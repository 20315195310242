
// pricing area start

.single-pricing-main{
    background: #F6F6F6;
    padding: 35px;
    border: 1px solid transparent;
    transition: .3s;
    @media #{$large-mobile} {
        padding: 20px;
    }
    .head{
        span{
            color: #B19777;
            color: #B19777;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.32px;
        }
        .price{
            color: #30373E;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px;
            margin-top: 5px;
        }
    }
    .body{
        p.disc{
            margin-bottom: 20px;
        }
        .available{
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 15px;
            color: #30373E;
            &.not{
                color: #7D7D7D;
            }
        }
        .rts-btn{
            border-radius: 0;
            margin-top: 30px;
            transition: .3s;
            color: #30373E;
            &:hover{
                background: var(--color-primary);
                border-color: var(--color-primary);
                color: #fff;
            }
        }
    }
    &:hover{
        border: 1px solid var(--color-primary);
    }
}



