.bg-footer-image{
    background-image: url(../images/footer/01.jpg);
}

.footer-area-wrapper-one{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media #{$md-layout} {
        flex-wrap: wrap;
        gap: 60px;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
    }
    .single-footer-wized{
        @media #{$md-layout} {
            flex-basis: 45%;
        }
        @media #{$sm-layout} {
            flex-basis: 100%;
        }
        &:nth-child(1){
            max-width: 347px;
        }
        form{
            input{
                border-bottom: 1px solid #4C535F;
                padding-left: 0;
                padding: 15px 0;
                color: #A1A1A1;
                padding-right: 25px;
            }
        }
        p.disc{
            color: #A1A1A1;
            margin-top: 34px;
            margin-bottom: 30px;
        }
        form{
            .input-email{
                position: relative;
                i{
                    position: absolute;
                    right: 0;
                    bottom: 48px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}


.single-footer-wized{
    .menu-links-area{
        .title{
            margin-bottom: 15px;
            color: #fff;
        }
        ul{
            list-style: none;
            padding: 0;
            margin-top: 25px;
            li{
                position: relative;
                padding-left: 20px;
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    width: 4px;
                    height: 4px;
                    background: #A1A1A1;
                    border-radius: 50%;
                    transform: translateY(-50%);
                }
                a{
                    color: #A1A1A1;
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
    .contact-information-1{
        .single-contact-info{
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 20px;
            .icon{
                font-size: 29px;
                color: var(--color-primary);
            }
            .information{
                a{
                    color: #fff;
                    font-size: 16px;
                }
                span{
                    display: block;
                    color: #A1A1A1;
                    font-size: 16px;
                }
            }
        }
    }
}


.copyright-style-one{
    border-top: 1px solid #4C535F;
    .copyright-area-main-wrapper-one{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:35px 0;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: center;
        }
        p{
            margin: 0;
            color: #D9D9D9;
        }
        ul{
            display: flex;
            align-items: center;
            gap: 53px;
            padding: 0;
            margin: 0;
            list-style: none;
            @media #{$sm-layout} {
                gap: 15px;
            }
            li{
                margin: 0;
                padding: 0;
            }
        }
    }
}


.disc-f{
    color: #7D7D7D !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; 
    letter-spacing: -0.32px;
}

.footer-two-main-wrapper-right{
    display: flex;
    align-items: flex-start;
    gap: 120px;
    @media #{$smlg-device} {
        gap: 40px;
    }
    @media #{$large-mobile} {
        gap: 0 !important;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    .single-footer-wized{
        flex-basis: 33.33%;
        form{
            .input-email{
                position: relative;
                button{
                    position: absolute;
                    right: 0;
                    top: 17px;
                    bottom: 20px;
                    color: #30373E;
                    max-width: max-content;
                }
            }
            input{
                border-bottom: 1px solid #E3E3E3;
                padding-left: 0;
                padding: 15px 0;
                color: #7D7D7D;
                padding-right: 25px;
                &:focus{
                    border-color: var(--color-primary) !important;
                }
            }   
        }
        .location{
            margin-bottom: 23px;
            p{
                color: #30373E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
            }
        }
        .contact-call{
            margin-bottom: 24px;
            a{
                color: #30373E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 7px;
                display: block;
            }
            span{
                color: #7D7D7D;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.32px;
                display: block;
            }
        }
        ul{
            padding: 0;
            list-style: none;
        }
    }
}

.rts-cta-main-wrapper{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        right: 14%;
        width: 420px;
        top: 65%;
        height: 1px;
        background-image: linear-gradient(90deg, #E3E3E3, #E3E3E3 75%, transparent 75%, transparent 100%);
        background-size: 20px 1px;
        border: none;
        @media #{$large-mobile} {
            display: none;
        }
    }
    &::before{
        position: absolute;
        content: '';
        left: 14.9%;
        width: 420px;
        top: 22%;
        height: 1px;
        height: 1px;
        background-image: linear-gradient(90deg, #E3E3E3, #E3E3E3 75%, transparent 75%, transparent 100%);
        background-size: 20px 1px;
        border: none;
        @media #{$large-mobile} {
            display: none;
        }
    }
}

.rts-copyright-area-two{
    border: 1px solid #E3E3E3;
    @media #{$large-mobile} {
        border: none;
    }
    .copy-right-area-inner-two{
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$sm-layout} {
            flex-direction: column;
            align-items: center;
        }
        p{
            margin: 0;
            margin-bottom: 0 !important;
        }
        .right{
            ul{
                list-style: none;
                display: flex;
                align-items: center;
                gap: 70px;
                padding: 0;
                @media #{$small-mobile} {
                    gap: 11px;
                }
                li{
                    padding: 0;
                    margin: 0;
                    a{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.rts-footer-area-start{
    .footer-area-three-main-wrapper{
        .footer-two-main-wrapper{
            .social-style-two-wrapper{
                li{
                    a{
                        border: 1px solid #1E1F22;
                    }
                    &:hover{
                        a{
                            border-color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}

.footer-two-main-wrapper.three{
    display: flex;
    align-items: flex-start;
    gap: 126px;
    @media #{$laptop-device} {
        gap: 80px;
    }
    @media #{$smlg-device} {
        gap: 80px;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    @media #{$sm-layout} {
        gap: 40px;
    }
    .single-wized{
        .title{
            color: #D9D9D9;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
        }
        .contact-call{
            a{
                margin-bottom: 10px;
                display: block;
            }
        }
        .address{
            a{
                color: #D9D9D9;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                text-decoration-line: underline;
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
        form{
            position: relative;
            input{
                width: 439px;
                height: 50px;
                padding: 0 10px;
                border-bottom: 1px solid #1E1F22;
            }
            i{
                position: absolute;
                right: 10px;
                top: 36%;
                transform: translateY(-50%);
                cursor: pointer;
                color: #D9D9D9;
            }
        }
    }
}
.rts-testimonials-area{
    .title-style-three-center{
        .title{
            text-transform: inherit;
        }
    }
}

.copyright-3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$large-mobile} {
        flex-direction: column;
        justify-content: center;
    }
    p{
        margin-bottom: 0;
    }
    ul{
        list-style: none;
        display: flex;
        align-items: center;
        gap: 50px;
        padding: 0;
        margin: 0;
        @media #{$large-mobile} {
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 30px;
            gap: 15px;
        }
        li{
            margin: 0;
            padding: 0;
        }
    }
}

.padding-cont-sfp{
    padding: 0 150px;
    @media #{$laptop-device} {
        padding: 0 250px;
    }
    @media #{$smlg-device} {
        padding: 0 150px;
    }
    @media #{$md-layout} {
        padding: 0 50px;
    }
    @media #{$sm-layout} {
        padding: 0 20px;
    }
}

.showcase-fade-page{
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    background-image: url(../images/banner/27.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #1E1F22;
    .showcase-images-area-main-wrapper .hover-btn-area ul li.single-btn-bg{
        font-size: 60px;
        line-height: 70px;
        @media #{$laptop-device} {
            font-size: 45px;
            line-height: 40px;
        }
        @media #{$smlg-device} {
            font-size: 30px;
            line-height: 40px;
            margin: 2px 0;
            padding: 10px 0;
        }
        @media #{$sm-layout} {
            font-size: 22px;
            line-height: 28px;
        }
    }
    .showcase-images-area-main-wrapper{
        height: max-content;
        min-height: max-content;
        @media #{$laptop-device} {
            height: max-content;
            min-height: max-content;
        }
        @media #{$smlg-device} {
            height: max-content;
            min-height: max-content;
        }
    }
    .showcase-images-area-main-wrapper .hover-btn-area{
        top: 50%;
        @media #{$laptop-device} {
            top: 50%;
        }
        @media #{$smlg-device} {
            top: 50%;
        }
        @media #{$sm-layout} {
            top: 50%;
        }
    }
}

.index-four{
    .rts-cta-main-wrapper::before{
        top: 2%;
    }
}