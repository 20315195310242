.signle-blog-style-one{
    position: relative;
    .thumbnail{
        overflow: hidden;
        display: block;
        position: relative;
        margin-bottom: 30px;
        img{
            width: 100%;
            transition: 1s;
        }
        .tag{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px 12px;
            width: 150px;
            background: #fff;
            text-align: center;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .inner-content{
        .title{
            color: #30373E;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;  
            transition: .3s;
            border-bottom: 1px solid #E3E3E3;
            padding-bottom: 40px;
            margin-bottom: 0;
            margin-bottom: 24px;
            @media #{$large-mobile} {
                font-size: 20px;
                line-height: 28px;
            }
            &:hover{
                color: var(--color-primary);
            }  
        }
        .btn-blog-readmore{
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
    }
}

.blog-list-inner{
    .blog-style-three.when-bg-white::after{
        @media #{$sm-layout} {
              display: none !important;
        }
    }
}

.blog-style-three{
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 1;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    &::after{
        content: '';
        position: absolute;
        width: 519px;
        height: 319px;
        border: 1px solid #1E1F22;
        z-index: -1;
        left: 130px;
    }
    a.thumbnail{
        display: block;
        min-width: max-content;
        @media #{$large-mobile} {
            min-width: 100%;
        }
        img{
            min-width: max-content;
            @media #{$large-mobile} {
                min-width: 100%;
            }
        }
    }
    .body-information{
        padding: 30px;
        @media #{$sm-layout} {
            padding-left: 0;
        }
        .blog-top{
            margin-bottom: 12px;
            gap: 38px;
            display: flex;
            align-items: center;
            .tag{
                color: #D9D9D9;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: -0.32px;
                position: relative;
                &::after{
                    position: absolute;
                    content: '';
                    right: 0;
                    width: 6px;
                    height: 6px;
                    background: #88919B;
                    border-radius: 50%;
                    left: 120%;
                    top: 10px;
                }
            }
            span{
                color: #88919B;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: -0.32px;
            }
        }
        a{
            .title{
                color: #D9D9D9;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px;

                text-transform: capitalize;
                margin-bottom: 0;
                text-transform: capitalize;
                position: relative;
                display: inline;
                background-image: -webkit-gradient(linear, left top, right top, from(var(--color-white)), to(var(--color-white)));
                background-image: -webkit-linear-gradient(left, var(--color-white) 0%, var(--color-white) 100%);
                background-image: -o-linear-gradient(left, var(--color-white) 0%, var(--color-white) 100%);
                background-image: linear-gradient(to right, var(--color-white) 0%, var(--color-white) 100%);
                background-size: 0px 1px;
                background-position: 0px 95%;
                -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                -o-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                padding: 0.1% 0px;
                background-repeat: no-repeat;
            }
            &:hover{
                .title{
                    background-size: 100% 1px !important;
                }
            }
        }
        p.disc{
            margin-bottom: 20px;
            margin-top: 15px;
        }
        a.btn-read-more-blog{
            color: var(--color-primary);
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
}

.border-topbottom-h3{
    border-top: 1px solid #1E1F22;
}

.rts-blog-area-start-six{
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    .thumbnail{
        img{
            min-width: 100%;
        }
    }
    &::after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #F6F6F6;
        z-index: 1;
        pointer-events: none;
        transition: .3s;
    }
    .inner-content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        z-index: 2;
        .top{
            display: flex;
            align-items: center;
            gap: 38px;
            span{
                color: #30373E;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: -0.32px;
                transition: .3s;
            }
        }
        .title{
            color: #30373E;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            margin-top: 20px;
            margin-bottom: 20px;
            @media #{$large-mobile} {
                font-size: 16px;
                line-height: 30px;
            }
        }
        .rts-read-more{
            color: #30373E;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: -0.32px;
            transition: .3s;
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }
    &:hover{
        &::after{
            height: 0;
        }
        .inner-content{
            .top{
                span{
                    color: #fff;
                }
            }
            .title{
                color: #FFF;
            }
            .rts-read-more{
                color: #fff;
            }
        }
    }
}

.blog-style-three.when-bg-white{
    &::after{
        border: 1px solid #E3E3E3;
    }
    .body-information{
        .blog-top{
            .tag{
                color: #B19777;
            }
        }
        .title{
            color: #30373E;
            transition: .3s;
            &:hover{
                color: var(--color-primary);
            }
        }
    }
}

.service-left-sidebar-wized{
    .search-area{
        position: relative;
        input{
            width: 100%;
            background: #fff;
            height: 50px;
            padding-right: 50px;
        }
        i{
            position: absolute;
            cursor: pointer;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 0;
            display: block;
            img{
                height: auto;
                transition: .3s;
                min-width: max-content;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 0;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 86px;
                    height: auto;
                    transition: .3s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: auto;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 13px;
            background: #fff;
            border-radius: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
            }
        }
    }
}




.single-blog-list-style-one{
    .thumbnail{
        display: block;
        overflow: hidden;
        img{
            min-width: 100%;
        }
    }
    .blog-body{
        padding: 40px;
        background: #F6F6F6;
        @media #{$sm-layout} {
            padding: 20px;
        }
        .top{
            display: flex;
            align-items: center;
            gap: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
            .single{
                display: flex;
                align-items: center;
                gap: 12px;
                i{
                    color: var(--color-primary);
                }
                span{
                    color: #74787C;
                }
            }
        }
        .inner{
            margin-top: 25px;
            .title{
                color: #30373E;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
                text-transform: capitalize;
                margin-bottom: 30px;
                transition: .3s;
                @media #{$md-layout} {
                    font-size: 26px;
                    line-height: 36px;
                }
                @media #{$sm-layout} {
                    font-size: 26px;
                    line-height: 36px;
                }
                @media #{$large-mobile} {
                    font-size: 20px;
                    line-height: 34px;
                    br{
                        display: none;
                    }
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
            .rts-btn{
                border-radius: 0;
                padding: 15px 50px;
            }
        }
    }
}

.brand-style-three{
    a{
        img{
            transition: .3s;
            &:hover{
                filter: invert(1);
            }
        }
    }
}



