// why choose us section
.single-choose-style-two{
    display: flex;
    align-items: flex-start;
    gap: 19px;
    padding: 24px;
    border: 1px solid #E3E3E3;
    transition: .4s;
    @media #{$small-mobile} {
        padding: 15px;
    }
    .icon{
        min-width: max-content;
    }
    .right-content{
        .title{
            color: #30373E;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 10px;
        }
        p.disc{
            color: #7D7D7D;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;
        }
    }
    &:hover{
        background: #F6F6F6;
        border: 1px solid transparent;
        transform: translateY(-5px);
    }
}