

.after-before-main-wrapper-one{
    
  .cd-image-container {
    position: relative;
    width: 100%;
    margin: 0em auto;
  }
  .cd-image-container img {
    display: block;
    height: 100%;
  }

  .cd-image-label {
    position: absolute;
    font-weight: 500;
    letter-spacing: 1px;
    top: 0;
    right: 0;
    color: #ffffff;
    padding: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0.3s 0.7s;
    -moz-transition: -moz-transform 0.3s 0.7s, opacity 0.3s 0.7s;
    transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
  }
  .cd-image-label.is-hidden {
    visibility: hidden;
  }
  .is-visible .cd-image-label {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  .cd-resize-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    overflow: hidden;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .cd-resize-img img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: auto;
    max-width: none;
  }
  .cd-resize-img .cd-image-label {
    right: auto;
    left: 0;
  }
  .is-visible .cd-resize-img {
    width: 50%;
    /* bounce in animation of the modified image */
    -webkit-animation: cd-bounce-in 0.7s;
    -moz-animation: cd-bounce-in 0.7s;
    animation: cd-bounce-in 0.7s;
  }

  @-webkit-keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }
  @-moz-keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }
  @keyframes cd-bounce-in {
    0% {
      width: 0;
    }
    60% {
      width: 55%;
    }
    100% {
      width: 50%;
    }
  }
  .cd-handle {
    position: absolute;
    height: 44px;
    width: 44px;
    /* center the element */
    left: 50%;
    top: 50%;
    margin-left: -22px;
    margin-top: -22px;
    border-radius: 50%;
    background: var(--color-primary) url("https://images.clickfunnels.com/09/a714400d3211e7a0bd7d7140585054/cd-arrows.svg") no-repeat center center;
    cursor: move;
    box-shadow: 0 0 0 6px rgba(214, 214, 214, 0.2), 0 0 10px rgba(0, 0, 0, 0.6), inset 0 1px 0 rgba(255, 255, 255, 0.3);
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0) scale(0);
    -moz-transform: translate3d(0, 0, 0) scale(0);
    -ms-transform: translate3d(0, 0, 0) scale(0);
    -o-transform: translate3d(0, 0, 0) scale(0);
    transform: translate3d(0, 0, 0) scale(0);
  }
  .cd-handle.draggable {
    /* change background color when element is active */
    background-color: #445b7c;
  }
  .is-visible .cd-handle {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    -moz-transform: translate3d(0, 0, 0) scale(1);
    -ms-transform: translate3d(0, 0, 0) scale(1);
    -o-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
    -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0s 0.7s;
    -moz-transition: -moz-transform 0.3s 0.7s, opacity 0s 0.7s;
    transition: transform 0.3s 0.7s, opacity 0s 0.7s;
  }
}


.after-before-main-wrapper-conetnt{
  p.disc{
    margin-bottom: 25px;
  }
  .step-by-step{
    display: flex;
    align-items: center;
    gap: 30px;
    svg{
      min-width: max-content;
    }
    .title{
      margin-bottom: 8px;
    }
    p.disc{
      max-width: 90%;
      margin-bottom: 0;
    }
  }
}