.search-input-area.show {
    visibility: visible;
    transform: inherit;
    opacity: 1;
}

.search-input-area {
    transition: all 500ms ease;
    visibility: hidden;
    transform: translateY(-100%);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 57px 0;
    background: white;
    box-shadow: 1px 1px 50px rgb(0 0 0 / 46%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$small-mobile} {
        padding: 20px 0;
    }

    .search-input-inner {
        display: flex;
        align-items: center;
        position: relative;

        .input-div {
            width: 80%;
            display: flex;
            align-items: center;
            margin: auto;

            input {
                background: #F7F7F7;
                border-radius: 5px;
                height: 55px;
            }

            button {
                max-width: max-content;
                padding: 17.5px;
                background: var(--color-primary);
                display: flex;
                color: #fff;
                align-items: center;
                justify-content: center;
                display: block;
                margin-left: -9px;
                border-radius: 0 5px 5px 0;
            }
        }
    }
    .search-close-icon {
        cursor: pointer;
        position: absolute;
        right: 18px;
        top: 12px;
        @media(max-width:500px){
            display: none;
        }

        i {
            position: relative;
            z-index: 1;
            color: var(--color-primary);
            transition: .3s;
            font-size: 18px;

            &::after {
                position: absolute;
                height: 45px;
                width: 45px;
                content: '';
                border-radius: 5px;
                background: var(--color-primary-alta);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                transition: .3s;
            }
        }

        &:hover {
            i {
                color: var(--color-primary);

                &::after {
                    background: var(--color-primary-alta);
                }
            }
        }
    }
}