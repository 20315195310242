
// social-with text
.social-text-follow{        
    display: flex;
    align-items: center;
    gap: 30px;
    span{
        color: #fff;
    }
    ul{
        padding: 0;
        display: flex;
        align-items: center;
        gap: 19px;
        list-style: none;
        li{
            margin: 0;
            a{
                i{
                    color: #fff;
                    transition: .3s;
                }
                &:hover{
                    i{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}